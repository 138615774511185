<template>
  <div class="container">
    <div class="col-lg-4 col-md-6 ml-auto mr-auto">
      <card class="card-reset card-white">
        <template slot="header">
          <div class="header_container d-flex justify-content-between align-items-center">

          </div>

        </template>

        <div v-if="showSuccess">
          <div class="alert alert-success" role="alert">
            {{ $t("login.reset_success") }}
          </div>

          <router-link to="/login" class="btn btn-link w-100">
            <button role="link" type="submit" class="btn btn-primary w-100 d-block mb-2">
              {{ $t("login.Back to login") }}
            </button>
          </router-link>

          <!-- <div class="card-footer">
            <small>
              <router-link class="ml-2" to="/login">
                {{ $t("login.Back to login") }}</router-link>
            </small>
          </div> -->
        </div>

        <div v-if="!showSuccess">
          <div v-if="showError">
            <div class="alert alert-danger" role="alert">
              {{ error }}

              <router-link v-if="showHow" to="/lock">
                link
              </router-link>
            </div>
          </div>

          <b-form @submit.prevent="onSubmit">
            <b-form-group :label="$t('login.New password') + ':'" label-for="New password">
              <b-form-input id="new-password" v-model="form.new_password" type="password" required
                :placeholder="$t('login.New password')" />
            </b-form-group>
            <b-form-group :label="$t('login.confirmpassword') + ':'" label-for="New Password Confirmation">
              <b-form-input id="password" v-model="form.password" type="password" required
                :placeholder="$t('login.confirmpassword')" />
            </b-form-group>
            <button type="submit" class="btn btn-primary w-100 d-block mb-2">
              {{ $t("login.Reset Password") }}
            </button>
          </b-form>
        </div>

      </card>


    </div>
  </div>
</template>

<script>
import { useApi } from "@/modules/api";
import { store } from "@/store";
// import LoadOverlay from "I:/workspace_test/webserver_test/frontend/vue-black-dashboard-pro-v1.4.0/src/components/loadOverlay.vue";

export default {
  name: "PasswordReset",
  // components: { LoadOverlay },
  data() {
    return {
      form: {
        new_password: "",
        password: "",
        // new_password: '',
        // password: ''
      },
      token: this.$route.query.token,
      showSuccess: false,
      showError: false,
      showHow: false,
      error: "",
    };
  },
  computed: {
    errorFields() {
      return store.getters.errorFields;
    },
    isLoading() {
      return store.getters.isLoading;
    },
  },
  mounted() {
    this.token = this.$route.query.token;
    //console.log("token:", this.token);
    // document.body.classList.add("PasswordReset");
  },
  created() {
    if (localStorage.getItem("languageSelected") === "true") {
      this.$i18n.locale = "de";
    } else {
      this.$i18n.locale = "en";
    }
  },
  methods: {
    onSubmit() {
      this.showError = false;
      this.showHow = false;
      if (this.form.new_password != this.form.password) {
        this.error = this.$i18n.t("Passwords should match");
        this.showError = true;
      } else {
        useApi
          .post("/api/auth/password_reset/confirm/", {
            token: this.token,
            password: this.form.password,
          })
          .then(() => {
            //console.log(response);
            this.showSuccess = true;
          })
          .catch((error) => {
            //console.log(error);
            if (error.password) {
              this.error = error.password[0];
            } else {
              this.error =
              this.$i18n.t("login.reset_failed");
              this.showHow = true;
            }

            this.showError = true;
          });
      }
    },
  },
};
</script>
<style scoped lang="scss">
.logo {
  text-align: center;
  padding: 1rem 0 3rem;

  svg {
    max-width: 200px;
  }
}
</style>
