<template>
  <div class="container">
    <div class="col-lg-4 col-md-6 ml-auto mr-auto">

      <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(submit)">
          <card class="card-lock card-white text-center">


            <h4 class="card-title" style="font-size: 18px;">{{ $t('login.enter') }}</h4>
            <ValidationProvider name="email" rules="email" mode="lazy" v-slot="{ passed, failed, errors }">
              <base-input :required="true" v-model="email" placeholder="Email" addon-left-icon="tim-icons icon-email-85"
                type="text" :error="errors[0]" :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
              </base-input>
            </ValidationProvider>


            <base-button native-type="submit" slot="footer" type="info" size="lg" round>
              {{ $t('login.send') }}
            </base-button>
          </card>
        </form>
      </ValidationObserver>


    </div>
  </div>
</template>
<script>
import axios from 'axios';
import { useApi } from "../../modules/api";
export default {

  data() {
    return {
      email: "",

    };
  },
  methods: {
    async submit() {
      await useApi.post(
          "/api/auth/password_reset/", { email: this.email })
        .then(response => {
          alert(this.$i18n.t('login.checkemail'));
        })
        .catch(error => {
          console.error('Error:', error);
          alert(this.$i18n.t('login.send_failed'));
        });
    }
  },
  created() {
    if (localStorage.getItem("languageSelected") === "true") {
      this.$i18n.locale = "de";
    } else {
      this.$i18n.locale = "en";
    }
  },


};
</script>
<style></style>
